import {ShopActionType} from '../../../types';
import {extractSpinUrlSuffix} from '../../../common/utils';
import {I18n} from '../../../common/translator/i18n';
import {booleanQueryParam} from '../../../common/utils/booleanQueryParam';

const AUTH_PATH_CORE = '/services/login_with_shop/authorize';
const AUTH_DOMAIN_LOCAL_CORE = 'https://shop1.myshopify.io';
const AUTH_DOMAIN_SPIN_PREFIX_CORE = 'https://shop1.shopify';
const AUTH_DOMAIN_PROD_CORE = window.location.origin;

/**
 * Returns the Shopify Core domain to use for the Authorize call, based on the context in
 * which shop-js is running. This is an alternative to getAuthDomain() to be used for
 * Shop Login M2.
 *
 * @returns {string} The Shopify Core domain to use for the Authorize call
 */
function getAuthDomainCore() {
  // Gets set at build time by rollup-replace
  // eslint-disable-next-line no-process-env
  const nodeEnv = process.env.NODE_ENV;

  switch (nodeEnv) {
    case 'development':
      return AUTH_DOMAIN_LOCAL_CORE;
    case 'spin':
      return `${AUTH_DOMAIN_SPIN_PREFIX_CORE}${extractSpinUrlSuffix()}`;
    case 'staging':
    case 'production':
    default:
      return AUTH_DOMAIN_PROD_CORE;
  }
}

const AUTH_DOMAIN_CORE = getAuthDomainCore();

/**
 * Build the Authorize URL that is proxied through Core. URL examples taken
 * from this PR: https://github.com/Shopify/shop-js/pull/270/files
 *
 * @param {string} analyticsTraceId Links analytics events together in one flow.
 * @param {string} analyticsContext Defines the context in which this authorize flow is used (i.e.
 * "loginWithShopClassicCustomerAccounts" or "loginWithShopSelfServe") to differentiate events when the same
 * flow is used in different contexts.
 * @param {boolean} isFullView Set to true to show the full login form, false to show the login button only.
 * @param {string} flow The flow to use for the login.
 * @param {string} flowVersion The version of the Sign in with Shop flow (eg. "sign_in" or "sign_up").
 * @param {boolean} emailVerificationRequired If `true` Pay will only respond with email verified users.
 * Otherwise Pay will respond with all users.
 * @param {boolean} signUpEnabled If `false` and Pay does not find a user with the given email address, it will
 * not show the Sign Up flow. Use when doing headless user lookups.
 * @param {boolean} hideCopy If `true`, Pay will not show any copy around the ULC.
 * @param {boolean} modalCustomized If `true`, Pay will verify the client is permitted to customize the modal.
 * @param {string} apiKey The app's api key.
 * @returns {string} The Authorize URL in Shopify Core
 */
export const buildCoreAuthorizeUrl = (
  analyticsTraceId?: string,
  analyticsContext?: string,
  isFullView?: boolean,
  flow?: ShopActionType,
  flowVersion?: string,
  emailVerificationRequired?: boolean,
  signUpEnabled?: boolean,
  hideCopy?: boolean,
  modalCustomized?: boolean,
  apiKey?: string,
): string => {
  /* eslint-disable @typescript-eslint/naming-convention */
  const params = new URLSearchParams({
    target_origin: window.location.origin,
    // Should probably be optional: https://github.com/Shopify/shop-identity/issues/553
    api_key: apiKey ?? '123',
    // BUILD_LOCALE is used for generating localized bundles.
    // See ./scripts/i18n-dynamic-import-replacer-rollup.mjs for more info.
    // eslint-disable-next-line no-process-env
    locale: process.env.BUILD_LOCALE || I18n.getDefaultLanguage(),
    ...(analyticsTraceId && {analytics_trace_id: analyticsTraceId}),
    ...(analyticsContext && {analytics_context: analyticsContext}),
    ...(flow && {flow}),
    ...(flowVersion && {flow_version: flowVersion}),
    ...booleanQueryParam('full_view', isFullView),
    ...booleanQueryParam(
      'email_verification_required',
      emailVerificationRequired,
    ),
    ...booleanQueryParam('sign_up_enabled', signUpEnabled),
    ...booleanQueryParam('hide_copy', hideCopy),
    ...booleanQueryParam('customize-modal', modalCustomized),
  });
  /* eslint-enable @typescript-eslint/naming-convention */

  return `${AUTH_DOMAIN_CORE}${AUTH_PATH_CORE}?${params}`;
};

export const getCoreAuthDomain = () => AUTH_DOMAIN_CORE;
