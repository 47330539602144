import ShopPayButtonBase from '../payButtonBase/shop-pay-button-base';
import {defineCustomElement} from '../../common/init';
import {getHostName, updateAttribute} from '../../common/utils';
import {MonorailTracker} from '../../common/analytics';
import {logError} from '../../common/logging';

import {Cart, PaymentOption, Variant} from './types';
import {
  buildRedirectUrl,
  constructLink,
  getCart,
  getVariantsFromAttributeString,
} from './utils';

const BASE_BUTTON_HTML = '<shop-pay-button-base></shop-pay-button-base>';

export default class ShopPayButton extends HTMLElement {
  static get observedAttributes() {
    return [
      'cart',
      'button-text',
      'disabled',
      'payment-option',
      'source',
      'source-token',
      'store-url',
      'variants',
    ];
  }

  private _monorailTracker: MonorailTracker;

  constructor() {
    super();

    this._monorailTracker = new MonorailTracker({
      elementName: 'shop-pay-button',
    });

    if (!customElements.get('shop-pay-button-base')) {
      customElements.define('shop-pay-button-base', ShopPayButtonBase);
    }

    this.attachShadow({mode: 'open'});
  }

  connectedCallback() {
    this._monorailTracker.trackElementImpression();

    const baseElement = this.shadowRoot;
    if (!baseElement) return;
    baseElement.innerHTML = BASE_BUTTON_HTML;

    this._updateButton();
  }

  attributeChangedCallback() {
    this._updateButton();
  }

  private _setOnClick(clickHandler: (() => void) | null = null) {
    const payBase = this.shadowRoot?.querySelector(
      'shop-pay-button-base',
    ) as HTMLElement;
    if (!payBase) return;

    payBase.onclick = clickHandler;
  }

  private _handleShopPayButtonClick = async () => {
    const storeUrl = this.getAttribute('store-url');
    if (!storeUrl) return;

    const paymentOption = this.getAttribute(
      'payment-option',
    ) as PaymentOption | null;
    const source = this.getAttribute('source');
    const sourceToken = this.getAttribute('source-token');
    const cartDetails = this.getAttribute('cart');

    const extractedHostname = getHostName(storeUrl);
    const transactionUrl = await getTransactionUrl(
      extractedHostname,
      cartDetails,
    );

    if (transactionUrl) {
      const redirectUrl = buildRedirectUrl(
        new URL(transactionUrl),
        paymentOption,
        source,
        sourceToken,
      );

      window.location.assign(redirectUrl);
    }
  };

  private _updateHref(variantIds: string) {
    const storeUrl = this.getAttribute('store-url');
    if (!storeUrl) return;

    const payBase = this.shadowRoot?.querySelector(
      'shop-pay-button-base',
    ) as HTMLElement;
    if (!payBase) return;

    const paymentOption = this.getAttribute(
      'payment-option',
    ) as PaymentOption | null;
    const source = this.getAttribute('source');
    const sourceToken = this.getAttribute('source-token');
    const redirectSource = this.getAttribute('redirect-source');
    const variants: Variant[] = getVariantsFromAttributeString(variantIds);

    const href = constructLink(
      storeUrl,
      variants,
      paymentOption,
      source,
      sourceToken,
      redirectSource,
    );

    this._setOnClick();
    updateAttribute(payBase, 'href', href);
  }

  private _updateButton() {
    const baseElement = this.shadowRoot;
    const payBase = this.shadowRoot?.querySelector(
      'shop-pay-button-base',
    ) as HTMLElement;
    if (!payBase || !baseElement) return;

    const disabled = this.hasAttribute('disabled');

    if (disabled) {
      updateAttribute(payBase, 'disabled', 'true');
      return;
    }

    const buttonText = this.getAttribute('button-text');
    if (buttonText) {
      payBase.innerHTML = buttonText || '';
      updateAttribute(payBase, 'hide-logo', '');
    } else {
      payBase.removeAttribute('hide-logo');
    }

    const variantIds = this.getAttribute('variants');
    if (variantIds) {
      this._updateHref(variantIds);
    } else {
      this._setOnClick(this._handleShopPayButtonClick);
    }
  }
}

/**
 * @param {string} extractedHostname The extracted hostname of the store URL
 * @param {string} cartDetails Details around the cart currently found on the page this button is located
 * @returns {string} The transaction url of the provided hostname, or undefined if an error occurred or hostname is invalid
 */
async function getTransactionUrl(
  extractedHostname: string | null,
  cartDetails: string | null,
): Promise<string | null> {
  if (!extractedHostname) {
    return null;
  }
  let cart: Cart;
  let authorizationToken: string;

  if (cartDetails) {
    cart = JSON.parse(decodeURIComponent(cartDetails));
    authorizationToken = await getAuthorizationToken(extractedHostname);
  } else {
    [cart, authorizationToken] = await Promise.all([
      getCart(extractedHostname),
      getAuthorizationToken(extractedHostname),
    ]);
  }

  // Match logic from https://github.com/Shopify/payment-sheet/blob/7585eeb38f52bf354332779670bb489f53d1008a/src/DataSource/Cart.ts#L46-L50
  // github.com/Shopify/payment-sheet/blob/7585eeb38f52bf354332779670bb489f53d1008a/src/components/CheckoutProcessor/CheckoutProcessorProvider.tsx#L372
  const hasSubscriptions: boolean = cart.items.some(
    (item) =>
      item.selling_plan_allocation !== undefined &&
      item.selling_plan_allocation.selling_plan !== undefined &&
      !item.selling_plan_allocation.selling_plan.fixed_selling_plan,
  );

  try {
    const checkoutResponse = await fetch(
      `https://${extractedHostname}/wallets/checkouts.json`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Basic ${authorizationToken}`,
        },
        body: JSON.stringify({
          checkout: {
            /* eslint-disable @typescript-eslint/naming-convention */
            cart_token: cart.token,
            presentment_currency: cart.currency,
            has_selling_plans: hasSubscriptions,
            secret: true,
            wallet_name: 'ShopifyPay',
            page_type: 'cart_page',
            /* eslint-enable @typescript-eslint/naming-convention */
          },
        }),
      },
    );

    const transactionUrl = (await checkoutResponse.json()).checkout
      .shop_pay_configuration.transaction_url;
    return transactionUrl;
  } catch (error) {
    logError(
      `Failed to fetch transaction url for ${extractedHostname} due to error: ${error}`,
    );
    return null;
  }
}

/**
 * @param {string} extractedHostname The extracted hostname of the store URL
 * @returns {string} The authorization token of the provided hostname
 */
async function getAuthorizationToken(
  extractedHostname: string,
): Promise<string> {
  try {
    const paymentsConfigResponse = await fetch(
      `https://${extractedHostname}/payments/config.json`,
    );
    const paymentsConfig = await paymentsConfigResponse.json();
    const authorizationToken = window.btoa(
      encodeURIComponent(paymentsConfig.paymentInstruments.accessToken),
    );
    return authorizationToken;
  } catch (error) {
    logError(
      `Failed to fetch authorization token for ${extractedHostname} due to error: ${error}`,
    );
    return '';
  }
}

/**
 * Define the shop-pay-button custom element.
 */
export function defineElement() {
  defineCustomElement('shop-pay-button', ShopPayButton);
}
