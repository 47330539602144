import {
  arrow,
  ComputePositionConfig,
  ComputePositionReturn,
  flip,
  limitShift,
  offset,
  Placement,
  shift,
  Side,
} from '@floating-ui/dom';

import {SMALL_SCREEN_WIDTH} from '../shop-sheet-modal/constants';

export interface PositionData extends ComputePositionReturn {
  staticSide: Side;
}

export type PositionOverrides =
  | {[key in Side]: string}
  | {[key: string]: never};

// Behavior type is the control plane that allows us to
// load different behavior configurations.
export enum PositionBehaviorType {
  Dynamic = 'DYNAMIC',
  Mobile = 'MOBILE',
  Center = 'CENTER',
}

// This object maps to some floating-ui constructs it's primary purpose is to
// enable the config[key -> PositionBehaviorType] setup and eliminate the `if -> else` code
// that would be present when checking for the different PositionBehaviorTypes
// The `config` property is what would get passed to the computePosition
// function provided by floating-ui
// Because floating-ui only calculates values it does not apply them, we need
// to handle how we want to deal with the values. The `computePosition` is an async function.
// The `fn` property is what is used to respond to the result of `computePosition` and to apply
// calculated position information to the floating/arrow elements.
// `PositionData` is all the calculated position values. The intent of the position module
// is to **only** deal with positioning. So we need to expose the position data
// so that the consumer can make informed decisions about how to apply animations or
// any other concern outside of positioning.
interface BehaviorConfiguration {
  config: ComputePositionConfig;
  fn: (options: ComputePositionReturn) => PositionData | null;
}

export const centerBehavior = (
  floatingElement: HTMLElement,
  overlayElement: HTMLElement,
  overrides: PositionOverrides = {},
): BehaviorConfiguration => ({
  config: {},
  fn: () => {
    const arrowElement: HTMLDivElement | null =
      floatingElement.querySelector('.arrow');

    if (arrowElement !== null) {
      arrowElement.style.display = 'none';
    }

    const overridesPresent = Object.keys(overrides).length > 0;

    // set the overrides via css variables
    if (overridesPresent) {
      Object.assign(floatingElement.style, overrides, {position: 'absolute'});
    }

    overlayElement.classList.toggle('centered', !overridesPresent);

    return null;
  },
});
// The object key repsents a placement side. Floating-ui can report placent as
// <side>-start, <side>, <side>-end, where side can be top, right, bottom, left.
// For the arrow we only care about the <side> and this is why we do placement.split(-).
// The mapping that is happening here represents where the arrow will be on the floating element.
// So, if the floating element is placed at the top the arrow will be placed on the bottom of
// the floating element.
const staticSideMap = new Map<Placement, Side>([
  ['top', 'bottom'],
  ['top-end', 'bottom'],
  ['top-start', 'bottom'],
  ['right', 'left'],
  ['right-end', 'left'],
  ['right-start', 'left'],
  ['bottom', 'top'],
  ['bottom-end', 'top'],
  ['bottom-start', 'top'],
  ['left', 'right'],
  ['left-end', 'right'],
  ['left-start', 'right'],
]);

export const dynamicBehavior = (
  floatingElement: HTMLElement,
  _overlayElement: any,
  _positionOverrides: any,
  anchorPosition?: Side,
): BehaviorConfiguration => {
  let arrowElement: HTMLDivElement | null =
    floatingElement.querySelector('.arrow');

  floatingElement.style.position = 'absolute';

  if (arrowElement === null) {
    arrowElement = document.createElement('div');
    arrowElement.className = 'arrow';
    floatingElement.appendChild(arrowElement);
  }

  return {
    config: {
      placement: anchorPosition ?? 'right',
      middleware: [
        offset(22),
        shift({
          limiter: limitShift({
            // modal.border-radius (32) + arrow.width(24) + margin of safety 8
            // this stops the caret from becoming detached from the modal due to
            // the border raidus
            offset: 64,
          }),
        }),
        flip({
          fallbackPlacements: anchorPosition ? [] : ['left', 'top', 'bottom'],
        }),
        arrow({element: arrowElement}),
        {
          name: 'center',
          async fn() {
            // Ideally here we would use floating-ui's detectOverflow, but it was
            // returning some confusing values which caused the modal to center
            // when it shouldn't have. There wasn't enough time to dig into the issue
            // so going with the brute force option and using a media query. When
            // there is time to look into this, the code change will live here.
            // https://floating-ui.com/docs/detectOverflow
            const mediaQuery = window.matchMedia(
              `screen and (((min-width: ${
                SMALL_SCREEN_WIDTH + 1
              }px) and (max-width: 1280px)) or (max-height: 750px))`,
            );

            return {
              data: {
                center: mediaQuery.matches,
              },
            };
          },
        },
      ],
    },
    fn: ({x, y, placement, strategy, middlewareData}) => {
      const {center} = middlewareData;

      if (center.center) {
        if (arrowElement !== null) {
          arrowElement.style.display = 'none';
        }
        // Fun math to center modal https://stackoverflow.com/a/62282447/603520
        Object.assign(floatingElement.style, {
          top: `${(window.innerHeight - floatingElement.offsetHeight) / 2}px`,
          left: `${(window.innerWidth - floatingElement.offsetWidth) / 2}px`,
          bottom: '',
          right: '',
        });

        return null;
      }

      Object.assign(floatingElement.style, {
        left: `${x}px`,
        top: `${y}px`,
        right: '',
        bottom: '',
      });

      const {arrow} = middlewareData;
      const staticSide = staticSideMap.get(placement) as Side;

      // only one of the x or y will be defined.
      // For top/bottom placement y will be defined
      // For left/right placement x will be defined
      // The -12px (1/2 of the 24x24) represents how much to shift the arrow off the
      // floating element to give the appearce of the arrow because in actuallity it is a square.
      if (arrowElement !== null) {
        Object.assign(arrowElement.style, {
          left: arrow?.x === undefined ? '' : `${arrow?.x}px`,
          top: arrow?.y === undefined ? '' : `${arrow?.y}px`,
          right: '',
          bottom: '',
          display: '',
          [staticSide]: '-12px',
        });
      }

      return {
        x,
        y,
        strategy,
        placement,
        staticSide,
        middlewareData,
      };
    },
  };
};

export const mobileBehavior = (
  floatingElement: HTMLElement,
): BehaviorConfiguration => ({
  config: {},
  fn: () => {
    Object.assign(floatingElement.style, {
      top: 'auto !important',
      right: '0 !important',
      bottom: '0 !important',
      left: 'auto',
    });

    return null;
  },
});

export const config = {
  [PositionBehaviorType.Dynamic]: dynamicBehavior,
  [PositionBehaviorType.Center]: centerBehavior,
  [PositionBehaviorType.Mobile]: mobileBehavior,
};
